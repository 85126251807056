.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.voucher-form {
		position: relative;

		.form-control {
			border: 1px solid #ced4da;
			padding: .375rem .75rem;
		}

		.btn-go {
			position: absolute;
			right: 0;
			width: 35px;
			top: 0;
			color: transparent;
			height: 36px;
			border-radius: 0 5px 5px 0;

			&:before {
				content: "\f061";
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
				display: block;
				font-size: 18px;
				color: #fff;
				color: var(--color-white);
				position: relative;
				top: -2px;
				right: 4px;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}